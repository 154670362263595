<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="1rem"
      width="2rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {}
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
              orient: 'horizontal', 
              x: 'center', 
              y: 'bottom',
              bottom: '0.01rem',
              // backgroundColor: 'rgba(0,0,0,0)',
              borderColor: '#ccc',       // 图例边框颜色
              padding: 1,                // 图例内边距，单位px，默认各方向内边距为5，
              itemGap: 1,               // 各个item之间的间隔，单位px，默认为10，
              itemWidth: 6,             // 图例图形宽度
              itemHeight: 6,            // 图例图形高度
              textStyle: {
                  color: '#fff'          // 图例文字颜色
              }
          },
        series: [
          {
            name: '告警分布',
            type: 'pie',
            labelLine: { 　　　　　　　　　　//去除饼图的指示折线label
              show: false
            },
            radius: ['50%', '70%'],
            data: newData.data
              }
            ]
          }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
