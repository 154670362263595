<template>
  <div>
    <Echart
      :options="options"
      id="yibiaoChart"
      height="6rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    chartData: {
      type: Number,
      default: 0,
    },
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        var colors = ["#FF0F1F", "#52C41A", "#F6A328", "#FF4D4F", "#FF0F1F"];

        this.options = {
          // 鼠标悬浮的提示

          tooltip: {
            formatter: "{b} : {c}",
          },

          series: [
            {
              type: "gauge",
              // 仪表盘的外圈 渐变色
              center: ["50%", "35%"], //位置
              radius: 131, // 半径
              axisLine: {
                lineStyle: {
                  width: 10,
                  color: [
                    // 这个是修改仪表盘的进度条颜色的
                    [0.2, "transparent]"],
                    [1, "transparent]"],
                  ],
                },
              },
              axisTick: {
                distance: 2,
                show: true, // 显示小刻度线
                length: 10, // 刻度线长度
                lineStyle: {
                  width: 1, // 刻度线宽度
                  color: "rgba(255, 255, 255, 0)", // 刻度线颜色
                },
              },
              splitLine: {
                // 长刻度线
                distance: 0,
                length: 12,
                lineStyle: {
                  width: 3,
                  color: "rgba(255, 255, 255, 0.8)",
                },
              },
              // 刻度数值
              axisLabel: {
                distance: 10, // 数值与刻度的距离
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: 18,
              },
              // 中心圆
              anchor: {
                show: true,
                showAbove: true,
                size: 45, // 中心圆的大小
                itemStyle: {
                  borderWidth: 15, //中心圆的边框大小
                  borderColor: "#fff", // 边框颜色
                  color: "transparent", // 中心圆颜色
                },
              },
              // 指针
              pointer: {
                width: 8,
                length: "75%",
                // offsetCenter: [0, '5%'],  指针位置 默认圆中心
                itemStyle: {
                  color: "#fff000",
                },
              },
              title: {
                show: false,
              },
              progress: {
                show: true, //是否显示进度条
                roundCap: true, //是否在两端显示成圆形
                width: 10, //进度条宽度
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0.5,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: colors[4], // 0% 处的颜色
                      },
                      {
                        offset: 0.25,
                        color: colors[3], // 25% 处的颜色
                      },
                      {
                        offset: 0.5,
                        color: colors[2], // 50% 处的颜色
                      },
                      {
                        offset: 0.75,
                        color: colors[1], // 75% 处的颜色
                      },
                      {
                        offset: 1,
                        color: colors[0], // 100% 处的颜色
                      },
                    ],
                    global: false,
                  },
                },
              },
              // 文字
              detail: {
                valueAnimation: true,
                fontSize: 50,
                formatter: "{value} %",
                color: "#3DFFDC",
                offsetCenter: [0, "70%"], // 文字的位置
              },
              data: [
                {
                  value: this.$props.chartData,
                },
              ],
            },
            {
              type: "gauge",
              center: ["50%", "35%"], //位置
              radius: 151, // 半径
              itemStyle: {
                color: "#FD7347",
              },
              axisLine: {
                lineStyle: {
                  width: 18,
                  color: [
                    [0.35, "#FF0030"],
                    [0.75, "#FF0300"],
                    [1, "#FF3000"],
                  ],
                },
              },
              pointer: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              detail: {
                show: false,
              },
              data: [
                {
                  value: newData,
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
