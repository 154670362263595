<!--状态检测任务统计 左上角显示-->
<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">设备故障率</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <div class="project-manage2">
          <yibiaoChart :chartData="option" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import yibiaoChart from "./chart.vue"
export default {
  props: {
    fault: {
      type: Number,
      // default: 0
    },
  },
  data() {
    return {
      option: this.$props.fault
    };
  },
  components: {
    yibiaoChart
  },
  computed: {},
  mounted() {
    console.log(this.$props.fault,'默认值')
  },
  methods: {},
};
</script>

<style scoped lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    margin-left: 150px;
    margin-bottom: 20px;
    color: #43ffc6;
    font-size: 20px;
  }
  .project-manage {
    min-width: 4rem;
    max-width: 100px;
    color: #fff;
    position: sticky;
    top: 70px;
    font-size: 14px;
    overflow-y: auto;
    height: 6rem;

    .manage-main {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      &.draging {
        background: rgba(36, 127, 255, 0.3);
      }
    }

    .my-project {
      line-height: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
      a {
        padding-left: 20px;
        text-decoration: none;
        color: #fff;
        width: 150px;
      }
    }

    .manage-title {
      .project-group {
        padding-left: 24px;
        height: 56px;
        border-bottom: 1px solid #27343e;
      }

      .btn-add-icon {
        cursor: pointer;

        &:hover {
          color: #2681ff;
        }
      }
    }

    .project-all {
      padding-left: 25px;
      transition: color 0.2s;
      cursor: pointer;
      background-image: url("../../../assets/images/index/unselect.png");
      background-repeat: round;
      width: 100%;

      &:hover {
        color: #2681ff;
      }
    }

    .project-checked-color {
      background-image: url("../../../assets/images/index/nav-menu-img.png");
      background-repeat: round;
      width: 100%;

      &:hover {
        color: #fff !important;
      }

      .project-name {
        color: #fff !important;
      }
    }

    .project-num {
      user-select: none;
      color: #bcc9d4;
    }

    .group-btns {
      display: none;
      color: #2681ff;

      i + i {
        margin-left: 10px;
      }
    }

    .main-project {
      position: relative;
      height: 36px;
      display: flex;
      font-size: 12px;
      margin-top: 5px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: color 0.2s;

      .project-name {
        width: 100px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
      }

      &:hover {
        .project-name {
          color: #2681ff;
        }
      }

      &.drag-enter {
        background: #171b22;
      }
    }

    .group-project {
      &:hover {
        .project-num {
          display: none;
        }

        .group-btns {
          display: inline-block;
        }
      }
    }
  }
  .project-manage2 {
    width: 100%;
    color: #fff;
    position: sticky;
    margin-left: 10px;
    top: 70px;
    font-size: 14px;
    overflow-y: auto;
    height: 100%;
    // text-orientation: upright;
    // /*文字禁止编辑*/
    // -moz-user-select: none; /*火狐*/
    // -webkit-user-select: none; /*webkit浏览器*/
    // -ms-user-select: none; /*IE10*/
    // -khtml-user-select: none; /*早期浏览器*/
    // user-select: none;
    .content_title {
      color: #c3cbde;
      font-size: 20px;
      // display: flex;
      margin-left: 20px;
      margin-top: 30px;
      flex-direction: column;
    }
    .number {
      display: inline;
    }
  }
}
</style>
