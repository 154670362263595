<template>
  <div>
    <Echart
      :options='options'
      id='bottomLeftChart'
      height='1rem'
      width='100%'
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {}
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(255,255,255,0.1)',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true,
                backgroundColor: '#7B7DDC'
              }
            }
          },
          legend: {
            data: ['预警次数'],
            textStyle: {
              color: '#B4B4B4'
            },
            top: '0%',
            show: false
          },
          grid: {
            x: '10%',
            width: '80%',
            x2:'2%',
            y: '10px',
            y2: '23%'
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: '#B4B4B4'
              }
            },
            axisTick: {
              show: false
            }
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisTick:{
                show:false,                         
              },
              // min: 0,
              // max: function(value) {return value.max}
            }
          ],
          series: [
            // {
            //   name: 'EA历史已完成统计',
            //   type: 'line',
            //   smooth: true,
            //   showAllSymbol: true,
            //   symbol: 'emptyCircle',
            //   symbolSize: 8,
            //   // yAxisIndex: 1,
            //   itemStyle: {
            //     normal: {
            //       color: '#F02FC2'
            //     }
            //   },
            //   data: newData.rateData
            // },
            // {
            //   name: '红相历史已完成统计',
            //   type: 'line',
            //   barWidth: 10,
            //   itemStyle: {
            //     normal: {
            //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: '#956FD4' },
            //         { offset: 1, color: '#3EACE5' }
            //       ])
            //     }
            //   },
            //   data: newData.barData
            // },
            {
              name: '预警次数',
              type: 'line',
              barGap: '-100%',
              barWidth: 10,
              itemStyle: {
                normal: {
                  // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   { offset: 0, color: 'rgba(156,107,211,0.8)' },
                  //   { offset: 0.2, color: 'rgba(156,107,211,0.5)' },
                  //   { offset: 1, color: 'rgba(156,107,211,0.2)' }
                  // ])
                  color: '#F02FC2'
                }
              },
              z: -12,
              data: newData.lineData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
