<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">告警情况统计</span>
          <dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <div class="project-manage">
        <div class="manage-main">
          <div class="main-project group-project" v-for="group in groups"  :key="group.id">
            <div
            class="my-project project-all"
            :class="{ 'project-checked-color': selectedGroupId==group.id }"
            @click="toggleProject(group.id)"
            >
            <span class="project-name">{{group.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="project-manage2">
        <dv-digital-flop :config="config_warningNum" style="width:271px;height:35px;" />
        <!-- <span class="content_title">告警次数: {{warningNum}}</span> -->
        <span class="content_title">告警趋势</span>
        <div class="content_title">
          <bottom-right-chart :cdata="LineChartData" />
        </div>
        <span class="content_title">告警分布</span>
        <div class="content_title1">
          <bottom-total :cdata="chart1Data"/>
          <bottom-total2 :cdata="chart2Data"/>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWarningData } from "@/api/index";
import bottomRightChart from './bottom/bottomRightChart/chart.vue'
import bottomTotal from './bottom/bottomTotal/chart.vue'
import bottomTotal2 from './bottom/bottomTotal/chart.vue'
export default {
  components: {
    bottomRightChart,
    bottomTotal,
    bottomTotal2
  },
  data () {
    return {
      warningNum: 0,
      selectedGroupId: '1001',
      LineChartData: {
        category: [],
        lineData:[]
      },
      chart1Data: {
        data: []
      },
      chart2Data: {
        data: []
      },
      groups: [{id: '1001', name: '今日', desc: 'today'},{id: '1002', name: '本周', desc: 'week'},{id: '1003', name: '本月', desc: 'month'},{id: '1004', name: '累计', desc: 'total'}]
    }
  },
  computed: {
   config_warningNum() {
      return {
            number: [this.warningNum],
            content: '告警次数:{nt}',
            style: {
              fontSize: 24,
              fill: '#3de7c9'
            }
          }
    },
  },
  mounted () {
    this.changeTiming("1001")
  },
  methods: {
    toggleProject(id) {
      this.selectedGroupId = id
      this.changeNumber(id)
    },
    changeNumber (id) {
    this.changeTiming(id)
  },
    changeTiming (id) {
      getWarningData().then(res=>{
        if(res.code == 200){
            this.LineChartData.category = []
            this.LineChartData.lineData = []
            let arr1 = []
            let arr2 = []
          if(id == "1001"){
            this.warningNum = res.data_result.today_len
            res.data_result.today_chart.forEach(v=>{
              this.LineChartData.category.push(v.hour)
              this.LineChartData.lineData.push(v.count)
            })
           res.data_result.today_warning.forEach(v=>{
              arr1.push({
                name: this.format(v.early_warning),
                value: v.count
              })
            })
            res.data_result.today_type.forEach(v=>{
              arr2.push({
                name: v.sensor_type,
                value: v.count
              })
            })
            this.chart1Data.data = arr1
            this.chart2Data.data = arr2
          } else if(id == "1002"){
            this.warningNum = res.data_result.weeks_len
            res.data_result.week_chart.forEach(v=>{
              this.LineChartData.category.push(v.day)
              this.LineChartData.lineData.push(v.count)
            })
            res.data_result.weeks_warning.forEach(v=>{
              arr1.push({
                name: this.format(v.early_warning),
                value: v.count
              })
            })
            res.data_result.weeks_type.forEach(v=>{
              arr2.push({
                name: v.sensor_type,
                value: v.count
              })
            })
            this.chart1Data.data = arr1
            this.chart2Data.data = arr2
          } else if(id == "1003"){
            this.warningNum = res.data_result.month_len
            res.data_result.month_chart.forEach(v=>{
              this.LineChartData.category.push(v.day)
              this.LineChartData.lineData.push(v.count)
            })
            res.data_result.month_warning.forEach(v=>{
              arr1.push({
                name: this.format(v.early_warning),
                value: v.count
              })
            })
            res.data_result.month_type.forEach(v=>{
              arr2.push({
                name: v.sensor_type,
                value: v.count
              })
            })
            this.chart1Data.data = arr1
            this.chart2Data.data = arr2
          } else if(id == "1004"){
            this.warningNum = res.data_result.altogether
            res.data_result.ret_chart.forEach(v=>{
              this.LineChartData.category.push(v.month)
              this.LineChartData.lineData.push(v.count)
            })
            res.data_result.ret_warning.forEach(v=>{
              arr1.push({
                name: this.format(v.early_warning),
                value: v.count
              })
            })
            res.data_result.ret_type.forEach(v=>{
              arr2.push({
                name: v.sensor_type,
                value: v.count
              })
            })
            this.chart1Data.data = arr1
            this.chart2Data.data = arr2
          }
        }
      })
    },
    format(value){
      if(value==1){
        return "正常"
      } else if(value==2){
        return "关注"
      } else if(value==3){
        return "预警"
      }
    }

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
    font-size: 18px;
  }
   .project-manage {
    min-width: 100px;
    max-width: 100px;
    color: #fff;
    position: sticky;
    top: 70px;
    font-size: 14px;
    overflow-y: auto;
    height: 100%;

    .manage-main {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      &.draging {
        background: rgba(36, 127, 255, 0.3);
      }
    }

    .my-project {
      line-height: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
      a {
          padding-left: 20px;
          text-decoration:none ; 
          color: #fff;
          width: 150px;
      }
    }

    .manage-title {
      .project-group {
        padding-left: 24px;
        height: 56px;
        border-bottom: 1px solid #27343e;
      }

      .btn-add-icon {
        cursor: pointer;

        &:hover {
          color: #2681ff;
        }
      }
    }

    .project-all {
      padding-left: 25px;
      transition: color 0.2s;
      cursor: pointer;
      background-image: url('../../../assets/images/index/unselect.png');
      background-repeat: round;
          width: 100%;

      &:hover {
        color: #2681ff;
      }
    }

    .project-checked-color {
      background-image: url('../../../assets/images/index/nav-menu-img.png');
      background-repeat: round;
          width: 100%;

      &:hover {
        color: #fff !important;
      }

      .project-name {
        color: #fff !important;
      }
    }

    .project-num {
      user-select: none;
      color: #bcc9d4;
    }

    .group-btns {
      display: none;
      color: #2681ff;

      i + i {
        margin-left: 10px;
      }
    }

    .main-project {
      position: relative;
      height: 36px;
      display: flex;
      font-size: 12px;
      margin-top: 5px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: color 0.2s;

      .project-name {
        width: 100px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
      }

      &:hover {
        .project-name {
          color: #2681ff;
        }
      }

      &.drag-enter {
        background: #171b22;
      }
    }

    .group-project {
      &:hover {
        .project-num {
          display: none;
        }

        .group-btns {
          display: inline-block;
        }
      }
    }
  }
  .project-manage2{
    min-width: 70%;
    max-width: 100%;
    color: #fff;
    position: sticky;
    margin-left: 10px;
    top: 50px;
    font-size: 14px;
    overflow-y: auto;
    height: 90%;
    .content_title{
      color: #c3cbde;
      font-size: 20px;
      display: flex;
      margin-left: 20px;
      margin-top: 20px;
      flex-direction: column;
    }
    .content_title1{
      color: #c3cbde;
      display: flex;
      margin-left: -20px;
      // flex-direction: column;
      // .chart1{
      //   // display: inline-block;
      //   // width: 2.25rem;
      //   // height: 2.25rem;
      // }
    }
  }
}
</style>
