<template>
  <div class="topo-render" v-if="configData.layer">
    <img src="../../image/wiringDiagram.png" class="dialog-img" alt="">
    <template v-if="sufferEquipmentList.length>0">
      <div v-for="(item,index) in sufferEquipmentList" :key="item.id">
      <div class="click-tap topo-render-wrapper-clickable click-tap-position" :style="{top: (60 + index*5) +'%'}" @click="clickLayerInfo(item)"></div>
    </div>
    </template>
    
    
    <!-- <template v-for="(component, index) in configData.components">
      <div
        class="topo-render-wrapper"
        :key="index"
        @click="doClickComponent(component)"
        @dblclick="doDbClickComponent(component)"
        :class="{
          'topo-render-wrapper-clickable': component.action.length > 0,
        }"
        v-show="
          component.style.visible == undefined ? true : component.style.visible
        "
        :style="{
          left: component.style.position.x + 'px',
          top: component.style.position.y + 'px',
          width: component.style.position.w + 'px',
          height: component.style.position.h + 'px',
          backgroundColor: component.style.backColor,
          zIndex: component.style.zIndex,
          borderWidth: component.style.borderWidth + 'px',
          borderStyle: component.style.borderStyle,
          borderColor: component.style.borderColor,
          transform: component.style.transform
            ? `rotate(${component.style.transform}deg)`
            : 'rotate(0deg)',
        }"
      >
        <component
          v-bind:is="parseView(component)"
          :detail="component"
          ref="spirit"
        />
      </div>
    </template> -->
  </div>
</template>

<script>
// import TopoBase from "./TopoBase";

// import topoUtil from "./util/topo-util";
import { getTopoDataById,getTreeConstructure } from "@/api/index";

export default {
  name: "TopoRender",
  // extends: TopoBase,
  components: {},
  data() {
    return {
      // configData: {},
      detailInfo: {},
      sufferEquipmentList: [],
    };
  },
  methods: {

    //获取所有设备信息
    getAllDeviceList() {
      getTreeConstructure().then(res=>{
      if (res.code == 200) {
        let id = sessionStorage.getItem("stationId")
        let arr = res.data.filter(item=>item.site_transformer_id == id)
        this.sufferEquipmentList = arr[0].test_suffer_equipment
      }
      }).catch(err=>{
      })
    },
    //点击往外层数据传值
    clickLayerInfo(item) {
      let detail = {...item}
      this.$emit("showDetail", detail);
    },
    parseView(component) {
      return topoUtil.parseViewName(component);
    },
    doClickComponent(component) {
      console.log(component)
      for (var i = 0; i < component.action.length; i++) {
        var action = component.action[i];
        if (action.type == "click") {
          this.handleComponentActuib(action);
        }
      }
    },
    doDbClickComponent(component) {
      for (var i = 0; i < component.action.length; i++) {
        var action = component.action[i];
        if (action.type == "dblclick") {
          this.handleComponentActuib(action);
        }
      }
    },
    handleComponentActuib(action) {
      var _this = this;
      if (action.action == "visible") {
        if (action.showItems.length > 0) {
          action.showItems.forEach((identifier) => {
            _this.showComponent(identifier, true);
          });
        }
        if (action.hideItems.length > 0) {
          action.hideItems.forEach((identifier) => {
            _this.showComponent(identifier, false);
          });
        }
      } else if (action.action == "service") {
        _this.sendFun(action);
      }
    },
    showComponent(identifier, visible) {
      console.log("show:" + identifier + ",visible:" + visible);
      var spirits = this.$refs["spirit"];
      for (var i = 0; i < spirits.length; i++) {
        var spirit = spirits[i];
        if (spirit.detail.identifier == identifier) {
          spirit.detail.style.visible = visible;
          break;
        }
      }
    },
  },
  mounted() {
    //这里只是纯前端项目，实际应该从后台拿取数据
    // var temp = sessionStorage.getItem("topoData");
    // getTopoDataById().then((res) => {
      // let temp = res.data;
      // this.configData = JSON.parse(temp);
      // this.configData = res.data
    // });
    this.getAllDeviceList()


  },
};
</script>

<style lang="scss">
.topo-render {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
  position: relative;
  //   background-clip: padding-box;
  //   background-origin: padding-box;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // //   background-color: rgb(253, 253, 253);
  //   float: left;
  border: 1px solid red;
  .dialog-img{
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .click-tap-position{
    position: absolute;
    top: 60%;
    left: 4%;
  }
  .click-tap{
    background-color: aqua;
    width: 50px;
    z-index: 222;
    height: 20px;
    opacity: 0;
  }

  .topo-render-wrapper {
    position: absolute;
    margin: 0 100px;
  }

  .topo-render-wrapper-clickable {
    cursor: pointer;
  }
}
</style>

