<template>
  <div id="center">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div style="display: flex; width: 300px; height: 50px">
          <span class="fs-xl text">接线图</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
        <div class="down">
          <div class="project-manage">
            <el-image
              @click="tap('preview')"
              style="width: 400px; height: 400px"
              :src="url"
            ></el-image>
          </div>
        </div>
        <el-dialog
          custom-class="custom-dialog"
          :visible.sync="dialogVisible"
          width="80%"
        >
          <div class="topo-fullscreen">
            <TopoRender @showDetail="showDetail" />
          </div>
          <el-dialog
            width="60%"
            :title="equipmentName"
            center
            custom-class="custom-dialog"
            :visible.sync="innerDialogVisible"
            append-to-body
          >
            <template v-if="detailInfo.length > 0">
              <div class="smain clearfix">
                <div class="smain-left">
                  <div class="border-container" v-if="hfctdata.length > 0">
                    <div class="name-title">HFCT局放实时数据</div>
                      <div class="grad1">
                        <el-image
                          class="imageStatus"
                          :src="
                            hfctdata[0].early_warning == '0'
                              ? srcGreen
                              : hfctdata[0].early_warning == '2'
                              ? srcRed
                              : srcYellow
                          "
                        ></el-image
                        ><span class="text"
                          >最大放电量幅值(dB)：
                          {{
                            Math.round(hfctdata[0].electricity_max * 100) / 100
                          }}</span
                        >
                      </div>
                      <div class="grad1">
                        <el-image
                          class="imageStatus"
                          :src="
                            hfctdata[0].early_warning == '0'
                              ? srcGreen
                              : hfctdata[0].early_warning == '2'
                              ? srcRed
                              : srcYellow
                          "
                        ></el-image
                        ><span class="text"
                          >平均放电量幅值(dB)：
                          {{
                            Math.round(hfctdata[0].average * 100) / 100
                          }}</span
                        >
                      </div>
                      <div class="grad1">
                        <el-image
                          class="imageStatus"
                          :src="
                            hfctdata[0].early_warning == '0'
                              ? srcGreen
                              : hfctdata[0].early_warning == '2'
                              ? srcRed
                              : srcYellow
                          "
                        ></el-image
                        ><span class="text"
                          >脉冲数(个)： {{ hfctdata[0].pulse_number }}</span
                        >
                      </div>
                      <div class="grad1">
                        <el-image
                          class="imageStatus"
                          :src="
                            hfctdata[0].early_warning == '0'
                              ? srcGreen
                              : hfctdata[0].early_warning == '2'
                              ? srcRed
                              : srcYellow
                          "
                        ></el-image
                        ><span class="text"
                          >监测位置： {{ hfctdata[0].sensor_position }}</span
                        >
                      </div>
                    <span class="top-left border-span"></span>
                    <span class="top-right border-span"></span>
                    <span class="bottom-left border-span"></span>
                    <span class="bottom-right border-span"></span>
                  </div>
                  <div v-else class="border-container">
                    暂无数据
                  </div>
                  <div class="border-container" v-if="uhfdata.length > 0">
                    <div class="name-title">uhf实时数据</div>
                        <div class="grad1">
                          <el-image
                            class="imageStatus"
                            :src="
                              uhfdata[0].early_warning == '0'
                                ? srcGreen
                                : uhfdata[0].early_warning == '2'
                                ? srcRed
                                : srcYellow
                            "
                          ></el-image
                          ><span class="text"
                            >最大放电量幅值(dB)：
                            {{
                              Math.round(uhfdata[0].electricity_max * 100) / 100
                            }}</span
                          >
                        </div>
                        <div class="grad1">
                          <el-image
                            class="imageStatus"
                            :src="
                              uhfdata[0].early_warning == '0'
                                ? srcGreen
                                : uhfdata[0].early_warning == '2'
                                ? srcRed
                                : srcYellow
                            "
                          ></el-image
                          ><span class="text"
                            >平均放电量幅值(dB)： {{ uhfdata[0].average }}</span
                          >
                        </div>
                        <div class="grad1">
                          <el-image
                            class="imageStatus"
                            :src="
                              uhfdata[0].early_warning == '0'
                                ? srcGreen
                                : uhfdata[0].early_warning == '2'
                                ? srcRed
                                : srcYellow
                            "
                          ></el-image
                          ><span class="text"
                            >脉冲数(个)： {{ uhfdata[0].pulse_number }}</span
                          >
                        </div>
                        <div class="grad1">
                          <el-image
                            class="imageStatus"
                            :src="
                              uhfdata[0].early_warning == '0'
                                ? srcGreen
                                : uhfdata[0].early_warning == '2'
                                ? srcRed
                                : srcYellow
                            "
                          ></el-image
                          ><span class="text"
                            >监测位置： {{ uhfdata[0].sensor_position }}</span
                          >
                        </div>
                    <span class="top-left border-span"></span>
                    <span class="top-right border-span"></span>
                    <span class="bottom-left border-span"></span>
                    <span class="bottom-right border-span"></span>
                  </div>
                  <div v-else class="border-container">
                    暂无数据
                  </div>
                </div>

                <div class="smain-middle">
                  <!-- <div class="border-container"> -->
                  <div class="mapadd">
                    <el-image :src="imageSrc" class="imageS"></el-image>
                  </div>
                  <div class="snumber-show">
                    <ul>
                      <li>
                        <span class="span-name">运行状态</span>
                      </li>
                      <li>
                        <span class="span-number-show">正常</span>
                      </li>
                      <li>
                        <span class="span-name">组件运行状态</span>
                      </li>
                      <li>
                        <span class="span-number-show">正常</span>
                      </li>
                      <li>
                        <span class="span-name">故障位置</span>
                      </li>
                      <li>
                        <span class="span-number-show-Red">出线处B相</span>
                      </li>
                    </ul>
                  </div>
                  <div class="grad2" v-if="newTime !== ''">
                    <span class="text"
                      >最新上传时间:<br />
                      {{ newTime }}</span
                    >
                  </div>
                </div>
                <div class="smain-right">
                  <div class="border-container1" v-if="temdata.length > 0">
                    <div class="name-title">温度实时数据</div>
                    <div class="tem">
                      <div
                        class="grad1"
                        v-for="item in temdata"
                        :key="item.sensor_num"
                      >
                        <el-image
                          class="imageStatus"
                          :src="
                            item.early_warning == '0'
                              ? srcGreen
                              : item.early_warning == '2'
                              ? srcRed
                              : srcYellow
                          "
                        ></el-image
                        ><span class="text"
                          >进线处A相{{ item.number }}： {{ item.number }}℃</span
                        >
                      </div>
                    </div>

                    <span class="top-left border-span"></span>
                    <span class="top-right border-span"></span>
                    <span class="bottom-left border-span"></span>
                    <span class="bottom-right border-span"></span>
                  </div>
                </div>
              </div>
            </template>
          </el-dialog>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import TopoRender from "./TopoCom/TopoRender";
import { getPresentDataById } from "@/api/index";
export default {
  data() {
    return {
      url: require("../image/wiringDiagram.png"),
      dialogVisible: false,
      innerDialogVisible: false,
      detailInfo: [],
      equipmentName: "",
      // svg_data: ""
      // 加进来的
      imageSrc: require("../css/byq-1.png"),
      srcRed: require("../css/red.png"),
      warningTEV: "正常",
      warningAE: "预警",
      warningHFCT: "关注",
      newTime: "",
      t_max1: 123,
      srcGreen: require("../css/green.png"),
      srcYellow: require("../css/yellow.png"),
    };
  },
  components: {
    TopoRender,
  },
  mounted() {},
  methods: {
    // 点击调用放大图片的方法，进行预览
    tap(attr) {
      this.dialogVisible = true;
    },
    //显示详情
    showDetail(param) {
      this.equipmentName = param.equipment_name;
      getPresentDataById({ test_equipment_id: param.test_equipment_id }).then(
        (res) => {
          if (res.code == 200) {
            res.data.map((item) => {
              if (item.data_line.length == 0) {
                //这里是为了让页面不报错坐的处理
                item.data_line[0] = {};
              }
            });
            this.detailInfo = [...res.data];
            this.innerDialogVisible = true;
            this.imageSrc = require("../css/kgg-2.png");
            if (res.data.length > 0) {
              // 此版本对接以传感器类型分组的数组式传感器数据
              res.data.forEach((v) => {
                if (v.sensor_type == "tev") {

                  this.tevdata = v.data_line;
                } else if (v.sensor_type == "ae") {
                  this.aedata = v.data_line;
                } else if (v.sensor_type == "hfct") {
                  this.hfctdata = v.data_line;
                } else if (v.sensor_type == "uhf") {
                  this.uhfdata = v.data_line;
                } else if (v.sensor_type == "tem") {
                  this.temdata = v.data_line;
                }
              });
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/dialog.scss";
#center {
  .bg-color-black {
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    height: 8.8rem;
    border-radius: 0.125rem;
  }

  .text {
    color: #43ffc6;
    font-size: 20px;
  }

  .chart-box {
    margin-top: 0.2rem;
    height: 2.125rem;

    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}

.down {
  display: flex;
  margin-left: -200px;
  float: left;
  padding: 0.2rem 0rem;
  padding-bottom: 0;
  width: 100%;
}

.project-manage {
  color: #fff;
  top: 70px;
  font-size: 14px;
  overflow-y: auto;
  height: 100%;

  .el-image {
    padding: 20px;
  }
}

.fs-xw {
  color: rgb(34, 170, 163);
  margin-left: 0.2rem;
}

.topo-fullscreen {
  height: 70vh;
  width: 100%;
}
.dialog-img {
  width: 300px;
}
.topo-dialog-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // background-image: url('../css/kgg-2.png'); /* 设置背景图像 */
  // background-repeat: no-repeat; /* 防止背景图像重复 */
  // background-position: center center; /* 背景图像居中对齐 */
}
.smain{
    width: 100%;
    height: 100%;
}
.smain-left{
    float: left;
    width: 30%;
    /* padding: 0 10px; */
}
.smain-right{
    margin-left: 70%;
    width: 30%;
}
.tev, .ae, .hfct{
    height: calc((100vh - 360px)/2);
}
.tem{
    height: 50vh;
}
.smain-middle{
    float: left;
    width: 31%;
    /* padding: 0 10px; */
}

.border-container {
    position: relative; 
    width: 100%;   
    height: calc((100vh - 350px)/2);
    padding: 10px;
    border: 1px solid #54dcf2;
}
.border-container1 {
    position: relative;    
    height: calc(100vh - 320px);
    padding: 10px;
    border: 1px solid #54dcf2;
}
.el-menu-demo li:hover {
    background-color: rgba(#51eef3, 0.6); /* 更改鼠标滑过的背景色 */
    color: #fbff05; /* 更改鼠标滑过的字体颜色 */
  }
  .border-container span.border-span {
    display: block;
    height: calc(100vh - 170px);
    position: absolute;
    width:35px;
    height: 35px;
}

.border-container1 span.top-left {
    top: -4px;
    left:-4px;
    border-top: 3px solid #54dcf2;
    border-left: 3px solid #54dcf2;
}

.border-container1 span.top-right {
    top:-4px;
    right:-4px;
    border-top: 3px solid #54dcf2;
    border-right: 3px solid #54dcf2;
}

.border-container1 span.bottom-left {
    bottom: -4px;
    left: -4px;
    border-bottom: 3px solid #54dcf2;
    border-left: 3px solid #54dcf2;
}

.border-container1 span.bottom-right {
    bottom: -4px;
    right: -4px;
    border-bottom: 3px solid #54dcf2;
    border-right: 3px solid #54dcf2;
}
.border-container1 span.border-span {
    display: block;
    position: absolute;
    width:35px;
    height: 35px;
}

.border-container span.top-left {
    top: -4px;
    left:-4px;
    border-top: 3px solid #54dcf2;
    border-left: 3px solid #54dcf2;
}

.border-container span.top-right {
    top:-4px;
    right:-4px;
    border-top: 3px solid #54dcf2;
    border-right: 3px solid #54dcf2;
}

.border-container span.bottom-left {
    bottom: -4px;
    left: -4px;
    border-bottom: 3px solid #54dcf2;
    border-left: 3px solid #54dcf2;
}

.border-container span.bottom-right {
    bottom: -4px;
    right: -4px;
    border-bottom: 3px solid #54dcf2;
    border-right: 3px solid #54dcf2;
}
.name-title{
    font-size:20px ;
    color: #00ffff;
}
#mapadd{
    position: absolute;
    top: 4%;
    left: 48%;
    height: 304px;
}
.snumber-show{
    position: absolute;
    top: 74%;
    left: 46%;
}
.snumber-show span.span-name{
    font-size:14px ;
    color:#fffd51 ;
}
.snumber-show span.span-number-show{
    font-size:18px ;
    color:#35f10f ;
    font-style: italic;
}
.snumber-show span.span-number-show-Red{
    font-size:18px ;
    color:#ca0101 ;
    font-style: italic;
}
.snumber-show span.span-number-show-orange{
    font-size:18px ;
    color:#eea41a ;
    font-style: italic;
}
.snumber-show li{
    line-height: 25px;
}

.grad1 {
  display: block;
  width: 100%;
  margin-top: 5px;
  height: 35px;
  float: left;
  text-align: left;
  border-radius: 18px;
  background-image: linear-gradient(
    to right,
    rgba(75, 233, 186, 0.137),
    rgb(79, 192, 173)
  );
  .imageStatus {
    position: relative;
    top: 1px;
  }
  .text {
    font-size: 14px;
    position: relative;
    left: 0px;
    top:-10px;
    color: #f8f5c3;
  }
}
.button {
  position: relative;
  padding: 0.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin: 0.5em;
}
.button.type1 {
  color: #00ffff;
}
.button.type1.type1::after, .button.type1.type1::before {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 2px solid;
  transition: all 0.6s ease;
  border-radius: 2px;
}
.button.type1.type1::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #edf0f3;
  border-right-color: #edf0f3;
}
.button.type1.type1::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #edf0f3;
  border-left-color: #edf0f3;
}
.button.type1.type1:hover:after, .button.type1.type1:hover:before {
  width: 100%;
  height: 100%;
}
.mapadd{
    height: 100%;
}
.imageS{
    background-size: cover;
    font-size: .16rem;
    color: #ffffff;
    position: absolute;
    top: 0%;
    left: 32%;
    width: 35%;
    height: 100%;
}


</style>
