<template>
  <div id="center">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">天气状况</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="down">
        <div class="project-manage2">
          <!-- <weather :weather="weather"></weather> -->
          <div class="weather-text-center">
            <h1 class="hero-card-title">站点天气</h1>
            <div class="hero-card text-center shadow-lg py-4 px-2">
              <img
                class="img-fluid"
                src="../../../assets/images/weather/sunny-hero.png"
                alt="..."
              />
              <div class="pt-3">
                <h2>31ºC</h2>
                <h3>晴天</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import weather from "./weather.vue"
// import { getWeather } from "../../../api/index";
export default {
  data() {
    return {
      weather: "",
      lower: "",
      higher: "",
    };
  },
  computed: {},
  // components: {
  //   weather
  // },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      // getWeather().then((res) => {
      //   console.log(res);
      //   this.weather = res.data;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .bg-color-black {
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    height: 8.8rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #43ffc6;
    font-size: 20px;
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    // .ranking {
    //   padding: 0.125rem;
    //   width: 59%;
    // }
  }
  .project-manage2 {
    min-width: 100%;
    max-width: 100%;
    color: #fff;
    position: sticky;
    margin-left: 10px;
    text-align: center;
    font-size: 32px;
    overflow-y: auto;
    height: 100%;
    .weather-text-center {
      .hero-card {
        background-color: transparent;
        width: auto;
        border-radius: 10px;
      }
      .img .hero-card-title {
        color: var(--whiteColor);
      }
    }
    .img-fluid {
      -webkit-transform: rotate(360deg);
      animation: rotation 15s linear infinite;
      -moz-animation: rotation 15s linear infinite;
      -webkit-animation: rotation 15s linear infinite;
      -o-animation: rotation 15s linear infinite;

      @keyframes rotation {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }
    }
  }
}
.fs-xw {
  color: rgb(34, 170, 163);
  margin-left: 0.2rem;
}
</style>
