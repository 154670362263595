<template>
  <div id="index">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <div v-else class="host-body">
      <div class="body-box">
        <div class="top-bar">
          <div class="right-bar"></div>
          <div class="left-bar"></div>
          <div class="return-btn">
            <span @click="$router.back(-1)" class="return-text">
              <i class="v-icon-back return-icon"></i>
              返回上一级
            </span>
          </div>
        </div>
        <div class="content-box-1">
          <div class="first-column">
            <div class="first-column-1">
              <!--状态检测任务统计-->
              <dv-border-box-9>
                <centerLeft1 :fault="equipmentFault" />
              </dv-border-box-9>
            </div>
            <div class="first-column-2">
              <!--告警情况统计-->
              <dv-border-box-9>
                <centerLeft2 />
              </dv-border-box-9>
            </div>
          </div>
          <div class="second-column">
            <div class="second-column-2">
              <!--图片-->
              <dv-border-box-10></dv-border-box-10>
            </div>
            <div class="second-column-3">
              <!--图片-->
              <dv-border-box-11
                style="font-size: 25px; color: blue"
                title="最新告警信息"
              >
                <dv-scroll-board
                  :config="config"
                  style="
                    margin-left: 0.5rem;
                    padding-top: 0.8rem;
                    width: 90%;
                    height: 3rem;
                  "
                />
              </dv-border-box-11>
            </div>
          </div>
          <div class="third-column">
            <div class="third-column-1">
              <!--带电检测数据统计-->
              <dv-border-box-9>
                <third />
              </dv-border-box-9>
            </div>
            <div class="third-column-3">
              <dv-border-box-9>
                <fourth />
              </dv-border-box-9>
            </div>
          </div>
        </div>
        <div class="content-box-2">
          <div class="first-1">
            <!--状态检测任务统计-->
            <dv-border-box-1>
              <span
                style="
                  color: #05effe;
                  font-size: 25px;
                  margin-left: 50px;
                  line-height: 50px;
                "
                >设备总数</span
              >
              <dv-digital-flop :config="configAll" />
            </dv-border-box-1>
          </div>
          <div class="first-2">
            <!--告警情况统计-->
            <dv-border-box-1>
              <span
                style="
                  color: #05effe;
                  font-size: 25px;
                  margin-left: 50px;
                  line-height: 50px;
                "
                >告警总数</span
              >
              <dv-digital-flop :config="configTotal" />
            </dv-border-box-1>
          </div>
          <div class="first-3">
            <!--状态检测任务统计-->
            <dv-border-box-1>
              <span
                style="
                  color: #05effe;
                  font-size: 25px;
                  margin-left: 50px;
                  line-height: 50px;
                "
                >故障总数</span
              >
              <dv-digital-flop :config="configGuzhang" />
            </dv-border-box-1>
          </div>
          <div class="first-4">
            <!--告警情况统计-->
            <dv-border-box-1>
              <span
                style="
                  color: #05effe;
                  font-size: 25px;
                  margin-left: 50px;
                  line-height: 50px;
                "
                >正常率</span
              >
              <dv-digital-flop :config="configZhengchangRate" />
            </dv-border-box-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getYujingData } from "@/api/index";
import centerLeft1 from "./component-station/centerLeft1.vue"; // 左上
import centerLeft2 from "./component-station/centerLeft2.vue"; // 左下
import third from "./component-station/third.vue"; // 右上
import fourth from "./component-station/fourth.vue"; // 右下
import { equipmentFaultCount, equipmentCount } from "@/api/index";

export default {
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      selectedGroupId: "1001",
      yujingData: [
        ['<span style="color:#37a2da;">行1列1</span>', "行1列2", "1", "告警"],
        ["行2列1", '<span style="color:#32c5e9;">行2列2</span>', "1", "告警"],
        ["行3列1", "行3列2", "1", '<span style="color:#FF0000;">告警</span>'],
        ["行4列1", '<span style="color:#9fe6b8;">行4列2</span>', "1", "告警"],
        ['<span style="color:#ffdb5c;">行5列1</span>', "行5列2", "1", "告警"],
        ["行6列1", '<span style="color:#ff9f7f;">行6列2</span>', "1", "告警"],
        ["行7列1", "行7列2", "1", '<span style="color:#FF0000;">告警</span>'],
        ["行8列1", '<span style="color:#e062ae;">行8列2</span>', "1", "告警"],
        ['<span style="color:#e690d1;">行9列1</span>', "行9列2", "1", "告警"],
        ["行10列1", '<span style="color:#e7bcf3;">行10列2</span>', "1", "告警"],
      ],
      groups: [
        { id: "1001", name: "今日", desc: "today" },
        { id: "1002", name: "本周", desc: "week" },
        { id: "1003", name: "本月", desc: "month" },
        { id: "1004", name: "累计", desc: "total" },
      ],
      configAll: {
        number: [100], //初始数据
        content: "{nt}",
        style: {
          //这里可以修改默认样式
          fontSize: 30, //字体大小
          fill: "#05EFFE", //字体颜色
        },
      },
      configTotal: {
        number: [100], //初始数据
        content: "{nt}",
        style: {
          //这里可以修改默认样式
          fontSize: 30, //字体大小
          fill: "#05EFFE", //字体颜色
        },
      },
      configGuzhang: {
        number: [100], //初始数据
        content: "{nt}",
        style: {
          //这里可以修改默认样式
          fontSize: 30, //字体大小
          fill: "#05EFFE", //字体颜色
        },
      },
      configZhengchangRate: {
        number: [100], //初始数据
        content: "{nt}%",
        style: {
          //这里可以修改默认样式
          fontSize: 30, //字体大小
          fill: "#05EFFE", //字体颜色
        },
      },
      equipmentFault: 0, //设备故障率
    };
  },
  components: {
    centerLeft1,
    centerLeft2,
    fourth,
    third,
  },
  computed: {
    config() {
      return {
        header: ["时间", "设备名称", "位置", "状态"],
        indexHeader: "序号",
        rowNum: 5, // 表行数
        headerBGC: "#FF121c", // 表头背景色
        oddRowBGC: "#9fe6b834", // 奇数行背景色
        evenRowBGC: "#37a2da33", // 偶数行背景色
        headerHeight: 50,
        waitTime: 2000, // 轮播时间间隔(ms)
        align: ["center"],
        data: this.yujingData,
      };
    },
  },
  mounted() {
    this.setData();
    this.searchEquipmentFaultCount();
    this.searchEquipmentCount();
    this.cancelLoading();
  },
  methods: {
    //设备统计
    searchEquipmentCount() {
      equipmentCount(sessionStorage.getItem("stationId")).then((res) => {
        if (res.code == 200) {
          this.configAll.number[0] = res.sensor_SE_count; //设备总数
          this.configTotal.number[0] = res.count_warning_sensor; //告警数量
          this.configGuzhang.number[0] = res.count_fault_server; //故障数量
          this.configZhengchangRate.number[0] = res.normal_rate * 100; //设备总数
        }
      });
    },
    //查询故障率
    searchEquipmentFaultCount() {
      equipmentFaultCount(sessionStorage.getItem("stationId")).then((res) => {
        if (res.code == 200) {
          this.equipmentFault = res.fault * 100;
        } else {
          this.$message.error(res.error);
        }
      });
    },
    setData() {
      getYujingData().then((res) => {
        this.yujingData = [];
        if (res.code == 200) {
          this.config.data = [];
          res.data.forEach((v) => {
            let arr = [];
            if (v.early_warning == "2") {
              arr.push(
                `<span style="color:#FFC90E;">${v.establish_time
                  .replace("T", " ")
                  .slice(5, -3)}</span>`,
                `<span style="color:#FFC90E;">${v.equipment_name}</span>`,
                `<span style="color:#FFC90E;">${v.site_name}</span>`,
                `<span style="color:#FFC90E;">关注</span>`
              );
            } else {
              arr.push(
                `${v.establish_time.replace("T", " ").slice(5, -3)}`,
                `${v.equipment_name}`,
                `${v.site_name}`,
                `<span style="color:#FF0000;">预警</span>`
              );
            }

            this.yujingData.push(arr);
          });
        }
        this.total = this.yujingData.length;
      });
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.el-tree {
  position: relative;
  cursor: default;
  background: transparent;
  color: #9ea8bd;
}
.el-tree-node__content:hover {
  background: rgba(104, 147, 215, 0.4);
}
.el-tree-node:focus > .el-tree-node__content {
  background: rgba(104, 147, 215, 0.4);
}
.host-body {
  .body-box {
    display: flex;
    flex-direction: column;
  }
}
// 宽高
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

//flex
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.mx-2 {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}
.fs-xl {
  font-size: 0.04rem;
}
.pt-2 {
  padding-top: 0.1rem;
}
.colorBlue {
  color: #ffba25b9 !important;
}
.fs-xw {
  color: rgb(34, 170, 163);
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}
.ullist {
  color: aliceblue;
  margin-left: 0.2rem;
  .item {
    color: azure;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
  }
}

.second-column-2 {
  height: 7rem;
  background-image: url("../../assets/css/bdz.png");
  background-size: cover;
  /* 或者可以使用contain根据需求 */
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.65;
  /* 设置整个div的透明度 */
}

.third-column-3 {
  height: 50%;
  /* 设置整个div的透明度 */
}
.second-column-3 {
  height: 4rem;
}
.content-box-2 {
  position: absolute;
  width: 40%;
  top: 3%;
  left: 30%;
  display: flex;
}
.first-1,
.first-2,
.first-3,
.first-4 {
  width: 3rem;
  height: 2rem;
}
.top-bar {
  z-index: 111;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #093c77;
  }
}
.right-bar {
  position: absolute;
  left: 10px;
  height: 5px;
  width: 100%;
  border-top: 1px solid #2681ff;
  border-left: 2px solid #2681ff;
  background: rgba(55, 126, 255, 0.04);
  border-top-left-radius: 5px;
  transform: skewX(-45deg);
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.left-bar {
  position: absolute;
  left: 0;
  top: 2px;
  height: 50px;
  width: 152px;
  border-right: 1px solid #2681ff;
  border-bottom: 1px solid #2681ff;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.return-btn {
  position: absolute;
  left: -31px;
  top: 0;
  width: 180px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #151b22;
  cursor: pointer;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
}
.return-text {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  transform: skewX(45deg);
  transition: 0.2s;

  &:hover {
    color: #60fafa;
  }
}

.return-icon {
  margin-right: 5px;
}
@import "@/assets/css/index.scss";
</style>
